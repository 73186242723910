<script setup>
definePageMeta({
  middleware: ['not-authenticated'],
})

const registerProfessionalStore = useRegisterProfessionalStore()
const router = useRouter()
const errorMessage = ref('')
const { checkIfEmailExists } = useApiUser()

async function nextStep() {
  if (
    registerProfessionalStore.generalData.password1 !==
    registerProfessionalStore.generalData.password2
  ) {
    errorMessage.value = 'Wachtwoorden komen niet overeen.'
  } else if (!registerProfessionalStore.phoneNumberIsValid) {
    errorMessage.value = 'Telefoonnummer is niet geldig.'
  } else {
    let emailExists = null
    try {
      emailExists = await checkIfEmailExists(
        registerProfessionalStore.generalData.email,
      )
    } catch (error) {
      errorMessage.value = 'Er is iets misgegaan.'
      return
    }

    if (emailExists) {
      errorMessage.value = 'Eeen account met dit email bestaat al.'
      return
    }

    await router.push('/register/professional/otp')
  }
}

function handlePhoneNumber(object) {
  registerProfessionalStore.phoneNumberIsValid = object.isValid
  registerProfessionalStore.generalData.phone = object.e164
}
</script>

<template>
  <RegisterStep
    title="Algemene gegevens"
    :error-message="errorMessage"
    :step="1"
    @next="nextStep"
  >
    <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
      <RegisterInputLabel title="Voornaam">
        <Input
          v-model="registerProfessionalStore.generalData.firstName"
          required
        />
      </RegisterInputLabel>

      <RegisterInputLabel title="Achternaam">
        <Input
          v-model="registerProfessionalStore.generalData.lastName"
          required
        />
      </RegisterInputLabel>

      <RegisterInputLabel title="Telefoonnummer">
        <PhoneNumber @update-result="handlePhoneNumber" />
        <p class="text-sm text-gray-500 absolute">
          We gebruiken dit om je identiteit te verifiëren.
        </p>
      </RegisterInputLabel>

      <RegisterInputLabel title="Email">
        <Input
          v-model="registerProfessionalStore.generalData.email"
          type="email"
          required
        />
      </RegisterInputLabel>

      <RegisterInputLabel title="Wachtwoord">
        <Input
          v-model="registerProfessionalStore.generalData.password1"
          type="password"
          required
          minlength="6"
        />
      </RegisterInputLabel>

      <RegisterInputLabel title="Bevestig wachtwoord">
        <Input
          v-model="registerProfessionalStore.generalData.password2"
          type="password"
          minlength="6"
          required
        />
      </RegisterInputLabel>
    </div>
  </RegisterStep>
</template>
