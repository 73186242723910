<script setup>
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
const emit = defineEmits(['update-result'])

const phoneNumber = ref()
const countryCode = ref('NL')

const emitUpdatedResult = (updatedResult) => {
  emit('update-result', updatedResult)
}
</script>

<template>
  <MazPhoneNumberInput
    v-model="phoneNumber"
    v-model:country-code="countryCode"
    class="max-w-full w-full rounded-xl"
    show-code-on-list
    no-flags
    orientation="responsive"
    no-example
    required
    country-selector-width="fit-content"
    :preferred-countries="['NL', 'FR', 'BE', 'DE', 'GB', 'ES']"
    @update="(newResult) => emitUpdatedResult(newResult)"
  />
</template>

<style>
.m-input-wrapper {
  background-color: rgb(240, 244, 248) !important;
  border: none !important;
}
</style>
